<template>
  <div class="tw-bg-white booking-container hidden-xs-only">
    <div class="inner tw-w-11/12 md:tw-w-10/12 tw-flex tw-flex-col tw-m-auto">
      <booking-steps />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingSteps from './components/BookingSteps.vue';

export default {
  components: {
    BookingSteps
  },
  data: () => ({
    defaultImage:
      'https://firebasestorage.googleapis.com/v0/b/travelo-91156.appspot.com/o/cities%2Fparis.png?alt=media&token=c7036777-dc45-41de-b837-19a098fd1f5c',
    currentBookingStep: 1
  }),
  computed: {
    ...mapGetters([ 'destinationBackground' ]),
    bgImage() {
      return this.destinationBackground || this.defaultImage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.booking-container {
  padding-top: 100px;
  min-height: 900px;

  .summary-block {
    padding: 10px;
  }

  .summary-block {
    background: rgba(255, 255, 255, 0.9);
    padding: 38px 46px;
  }

  .steps-block {
    border-radius: 10px;
  }
}

.inner {
  @media (max-width: 1024px) {
        width: calc(100vw - 72px);
  }
}
</style>
