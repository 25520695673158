<template>
  <div class="steps-container hidden-xs-only tw-flex tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center tw-font-poppins tw-font-medium tw-mb-10">
    <div 
    v-for="(tab, index) in tabs"
    :key="`booking_link_${index}`"
    class="tw-flex tw-items-center"
    :class="{'completed': completed(index)}"
    ><div
      class="d-flex circle align-center justify-center tw-w-6 tw-h-6 tw-rounded-full tw-bg-grey_light tw-text-xs tw-mr-2"
      >0{{index + 1}}</div>
      <router-link 
      :to="{name: tab.routeName}"
      ><div class="tw-text-gray-main tw-mr-3">{{tab.title}}</div></router-link>
      <template v-if="index < tabs.length - 1"
      ><shevron-right-icon
        class="tw-mr-3"
        /></template>
    </div>
  </div>
</template>

<script>
import ShevronRightIcon from '@/assets/bookingPage/shevron-right.svg';

export default {
  components: { 
    ShevronRightIcon,
  },
  data: () => ({
    tabs: [
      {
        title: 'Details',
        routeName: 'BookingDetails',
      },
      {
        title: 'Payments',
        routeName: 'BookingPayment',
      },
      {
        title: 'Confirmation',
        routeName: 'BookingDetails',
      },
    ]
  }),
  methods: {
    completed(index) {
      return this.tabs.findIndex(tab => tab.routeName === this.$route.name) >= index;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.steps-container {
  .completed {
    color: $primary;
    a div {
      color: $primary;
    }
    .circle{
      background: $primary;
      color: white;
    }
  }
}
</style>
